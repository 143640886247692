<template>
  <div>
    <el-form :model="query" label-width="90px" v-loading="loading">
      <el-row>
        <el-col :span="8">
          <el-form-item label="条件">
            <el-input
              v-model="query.keyword"
              maxlength="30"
              placeholder="展品名称"
              clearable
              @keyup.enter.native="handleSearch"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch"
          >搜索</el-button
        >
        <slot name="tip"></slot>
      </el-form-item>
    </el-form>

    <el-button
      type="primary"
      size="small"
      icon="el-icon-plus"
      plain
      style="margin-bottom: 10px"
      @click="handleUpdate('')"
      >新增
    </el-button>

    <el-table
      :data="tableData"
      v-loading="loading"
      @sort-change="handleOrder"
      ref="multipleTable"
      border
      fit
    >
      <el-table-column prop="sortNum" label="序号" width="60" align="center">
      </el-table-column>
      <el-table-column prop="name" label="展品名称" width="300">
      </el-table-column>
      <el-table-column label="品牌" prop="brand"></el-table-column>

      <el-table-column label="规格" prop="norms" width="100"></el-table-column>
      <el-table-column label="价格" prop="price" width="100"></el-table-column>
      <el-table-column label="产地/服务地区" prop="place"></el-table-column>
      <el-table-column label="编辑时间" width="160">
        <template slot-scope="scope">
          {{ moment(scope.row.editTime).format("YYYY-MM-DD HH:mm:ss") }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
        width="130"
      >
        <template slot-scope="scope">
          <div>
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row.eid)"
              >修改</el-button
            >
            <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="search"
      @current-change="search"
      :current-page.sync="query.pageIndex"
      :page-sizes="[10, 20, 30, 40]"
      :page-size.sync="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
import { del, meSearch } from "@/api/goods/search.js";

export default {
  data() {
    return {
      query: {
        pageIndex: 1,
        pageSize: 10,
        sortFd: null,
        sortBy: null,
      },
      loading: false,
      tableData: [],
      total: 0,
    };
  },
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar;
    },
    mobile() {
      return this.$store.state.app.device === "mobile";
    },
  },
  methods: {
    /** 搜索按钮操作 */
    handleSearch(queryParams) {
      this.query.pageIndex = 1;
      Object.assign(this.query, queryParams);

      this.search();
    },
    handleUpdate(goodsEid) {
      const url = `/goods/main/${goodsEid}`;

      if (this.isMobile()) {
        this.$router.push(url);
      } else {
        this.$alert("我已编辑完成!请单击确定。", "提示信息", {
          confirmButtonText: "确定",
          callback: () => {
            this.search();
          },
        });
        window.open(url);
      }
    },
    handleDelete(row) {
      this.$confirm(`是否要删除[${row.name}]?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading = true;
        del(row.eid).then(() => {
          this.loading = false;

          this.search();
          this.msgSuccess();
        });
      });
    },

    handleOrder(value) {
      this.query.orderFd = value.prop;
      this.query.orderBy = value.order;
      this.search();
    },
    search() {
      this.loading = true;      
      this.query.isAdmin = true;
      meSearch(this.query).then((res) => {
        this.tableData = res.datas;
        this.total = res.totalRecords;
        this.loading = false;
      });
    },
  },
  created() {
    this.search();
  },
};
</script>
<style scoped>
.box {
  position: fixed;
  right: 100px;
  bottom: 20px;
  z-index: 100;
}
</style>
